import {wordData} from "@utils/data";

const Word = () => {
  return (
    <div className="word-holder">
      <div className="word-inner uni-width">
        <p className="text-brand-blue heading-4 inter-display">
          Don&apos;t just take our word for it
        </p>

        <p className="four-18 text-secondary mt-20">
          Hear what our lenders love about Lendsqr
        </p>

        <div className="word-row-holder mt-48">
          <div className="word-row">
            <div className="word-column">
              {wordData
                .filter((filter, index) => index <= 1)
                .map((word, index) => {
                  return (
                    <div
                      className="word-cell mb-24 card-shadow"
                      key={word.name}
                    >
                      <div className="word-logo-row">
                        <img
                          src={word.logo}
                          alt={word.name}
                          className="word-logo"
                        />

                        <img src={word.country} alt={`${word.name} country`} />
                      </div>

                      <p className="text-primary mt-32 word-text body-md-regular">
                        {word.text}
                      </p>

                      <div className="word-bottom mt-28">
                        <img
                          src={word.ownerIcon}
                          className="word-owner mr-16"
                          alt={word.name}
                        />

                        <div className="word-bottom-name ">
                          <p className="text-primary body-md-bold">
                            {word.name}
                          </p>
                          <p className="text-tetiary body-sm-regular">
                            {word.role}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="word-column">
              {wordData
                .filter((filter, index) => index > 1 && index <= 3)
                .map((word, index) => {
                  return (
                    <div className="word-cell card-shadow" key={word.name}>
                      <div className="word-logo-row">
                        <img
                          src={word.logo}
                          alt={word.name}
                          className="word-logo"
                        />

                        <img src={word.country} alt={`${word.name} country`} />
                      </div>

                      <p className="four-16 text-primary mt-32 word-text">
                        {word.text}
                      </p>

                      <div className="word-bottom mt-28">
                        <img
                          src={word.ownerIcon}
                          className="word-owner mr-16"
                          alt={word.name}
                        />

                        <div className="word-bottom-name ">
                          <p className="six-16 text-primary">{word.name}</p>
                          <p className="four-14 text-tetiary">{word.role}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className="word-column">
              {wordData
                .filter((filter, index) => index > 3 && index <= 5)
                .map((word, index) => {
                  return (
                    <div className="word-cell card-shadow" key={word.name}>
                      <div className="word-logo-row">
                        <img
                          src={word.logo}
                          alt={word.name}
                          className="word-logo"
                        />

                        <img src={word.country} alt={`${word.name} country`} />
                      </div>

                      <p className="four-16 text-primary mt-32 word-text">
                        {word.text}
                      </p>

                      <div className="word-bottom mt-28">
                        <img
                          src={word.ownerIcon}
                          className="word-owner mr-16"
                          alt={word.name}
                        />

                        <div className="word-bottom-name ">
                          <p className="six-16 text-primary">{word.name}</p>
                          <p className="four-14 text-tetiary">{word.role}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {Word};
